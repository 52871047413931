import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-2.css';

const PageTwo = forwardRef((props, ref) => (
  <>
    <SEO title="Page 2" />
    <main className="page page-reverse page2">
      <div className="page__image-container">
        <div className="page__image-border-container">
          <StaticImage
            alt="Amanda folding the laundry with a surprised look on her face"
            aspectRatio={4472 / 3560}
            className="page__image"
            height={750}
            layout="constrained"
            loading="eager"
            objectFit="contain"
            placeholder="tracedSVG"
            src="../../images/page-2.png"
          />
        </div>
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            Slowly Amanda opened the dryer door and piled the laundry into the basket. Ignoring the socks, she
            picked up a towel when a little
            {' '}
            <strong>noise</strong>
            {' '}
            startled her.
          </p>
          <p>
            It seemed to be coming from inside the basket!
          </p>
          <p>
            As Amanda looked closer she saw something move and the
            {' '}
            <strong>
              noise got even louder!
            </strong>
            {' '}
            Almost like a cry.
          </p>
        </div>
      </div>
      <PreviousPage to="/page-1" />
      <NextPage to="/page-3" />
    </main>
  </>
));

const PageTwoContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageTwo ref={textRef} />;
};

export default PageTwoContainer;
